var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"columns has-text-right"},[_c('div',{staticClass:"column"},[_c('TableSearch',{on:{"change":_vm.search}})],1)]),_c('div',{staticClass:"table-wrapper"},[_c('table',{staticClass:"table is-fullwidth is-scrollable"},[_c('thead',[_c('tr',_vm._l((_vm.tableHeaders),function(header,index){return _c('td',{key:index},[_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'right' }),expression:"{ placement: 'right' }"}],class:header.class,attrs:{"title":"Click to narrow search"},on:{"click":function($event){return _vm.selectColumn(index)}}},[_vm._v(_vm._s(header.label))]),(!_vm.errorsSelected)?_c('a',{staticClass:"icon is-small is-size-7 has-text-primary",on:{"click":function($event){return _vm.sort(index)}}},[_c('i',{class:_vm.getIcon(index)})]):_vm._e()])}),0)]),_c('tbody',{staticClass:"is-fullwidth"},_vm._l((_vm.tableData),function(row,index){return _c('SummaryRow',_vm._b({key:index,on:{"companionRequested":_vm.disableButtons}},'SummaryRow',{
            row,
            tableHeaders: _vm.tableHeaders,
            companionRequested: _vm.companionRequested,
            companionRequestedRow: _vm.companionRequestedRow,
            index
          },false))}),1)]),(!_vm.tableData || !_vm.tableData.length)?_c('TableEmpty',{attrs:{"text":"No rows matching this filter"}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }