<template>
  <div>
    <div class="columns has-text-right">
      <div class="column">
        <TableSearch @change="search" />
      </div>
    </div>
    <div class="table-wrapper">
      <table class="table is-fullwidth is-scrollable">
        <thead>
          <tr>
            <td v-for="(header, index) in tableHeaders" :key="index">
              <span
                @click="selectColumn(index)"
                title="Click to narrow search"
                v-tippy="{ placement: 'right' }"
                :class="header.class"
                >{{ header.label }}</span
              >
              <a
                class="icon is-small is-size-7 has-text-primary"
                @click="sort(index)"
                v-if="!errorsSelected"
              >
                <i :class="getIcon(index)"></i>
              </a>
            </td>
          </tr>
        </thead>
        <tbody class="is-fullwidth">
          <SummaryRow
            v-for="(row, index) in tableData"
            :key="index"
            v-bind="{
              row,
              tableHeaders,
              companionRequested,
              companionRequestedRow,
              index
            }"
            @companionRequested="disableButtons"
          />
        </tbody>
      </table>
      <TableEmpty
        v-if="!tableData || !tableData.length"
        text="No rows matching this filter"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'MultiSummaryTable',
  components: {
    SummaryRow: () => import('./MultiSummaryRow'),
    TableSearch: () =>
      import('core/modules/table-search/components/TableSearch'),
    TableEmpty: () => import('core/components/TableEmpty')
  },
  data: () => ({
    companionRequested: false,
    companionRequestedRow: null,
    searchText: ''
  }),
  mounted() {},
  computed: {
    ...mapGetters('multi/summary', [
      'tableData',
      'tableHeaders',
      'errorsSelected'
    ])
  },
  methods: {
    ...mapActions('multi/summary', ['sort', 'search']),
    ...mapMutations('multi/summary', ['selectColumn']),
    disableButtons(boolean, rowIndex) {
      this.companionRequested = boolean
      this.companionRequestedRow = rowIndex
    },
    getIcon(index) {
      const order = this.tableHeaders[index].order

      if (order === 'asc') return 'fas fa-sort-up'
      else if (order === 'desc') return 'fas fa-sort-down'
      else return 'fas fa-sort'
    }
  }
}
</script>

<style lang="sass" scoped>
.table-wrapper
  overflow-x: scroll
  overflow-y: hidden
  white-space: nowrap
</style>
